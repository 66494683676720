export const GET_HOLIDAY_LIST = 'GET_HOLIDAY_LIST'

export interface DocsType {
  _id: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface HolidayType {
  docs: DocsType[],
  totalDocs: number,
  limit: number,
  totalPages: number,
  page: number,
  pagingCounter: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  prevPage: null | number,
  nextPage: null | number,
}

interface GetHolidayListType {
  type: typeof GET_HOLIDAY_LIST,
  payload: HolidayType
}

export interface HolidayState {
  holiday_list: HolidayType
}

export type HolidayActionType =
  | GetHolidayListType
import {
  ConfigActionType,
  ConfigState,
  GET_ORDER_CONFIG
} from './types'

const initialState: ConfigState = {
  config_list: []
}

export default function(state= initialState, action: ConfigActionType): ConfigState {
  switch (action.type) {
    case GET_ORDER_CONFIG:
      return {
        ...state,
        config_list: action.payload
      }
    default:
      return state
  }
}
import {
  HomeSliderActionType,
  HomeSliderState,
  ADD_HOMESLIDER,
  DELETE_HOMESLIDER,
  EDIT_HOMESLIDER,
  GET_HOMESLIDER,
  GET_HOMESLIDER_LIST,
  GET_TRASHED_HOMESLIDER_LIST,
  RESTORE_HOMESLIDER
} from "./types";

const initialState: HomeSliderState = {
  homeslider_list: [],
  homeslider: null,
  trashed_homeslider_list: []
}

export default function (state = initialState, action: HomeSliderActionType): HomeSliderState {
  switch (action.type) {
    case GET_HOMESLIDER_LIST:
      return {
        ...state,
        homeslider_list: action.payload
      }
    case DELETE_HOMESLIDER:
      return {
        ...state,
        homeslider_list: state.homeslider_list.filter(homeslider => homeslider._id !== action.payload._id)
      }
    case ADD_HOMESLIDER:
      return {
        ...state,
        homeslider_list: state.homeslider_list.concat(action.payload)
      }
    case EDIT_HOMESLIDER:
      return {
        ...state,
        homeslider_list: state.homeslider_list.map(homeslider => homeslider._id === action.payload._id ? action.payload : homeslider)
      }
    case GET_HOMESLIDER:
      return {
        ...state,
        homeslider: action.payload
      }
    case GET_TRASHED_HOMESLIDER_LIST:
      return {
        ...state,
        trashed_homeslider_list: action.payload
      }
    case RESTORE_HOMESLIDER:
      return {
        ...state,
        trashed_homeslider_list: state.trashed_homeslider_list.filter(state => state._id !== action.payload._id),
        homeslider_list: [...state.homeslider_list, action.payload]
      }
    default:
      return state
  }
}

import {
  OrderLimiterActionType,
  OrderLimiterState,
  GET_ORDER_LIMITER_LIST
} from './types'

const initialState: OrderLimiterState = {
  limiter_list: []
}

export default function (state= initialState, action: OrderLimiterActionType): OrderLimiterState {
  switch (action.type) {
    case GET_ORDER_LIMITER_LIST:
      return {
        ...state,
        limiter_list: action.payload
      }
    default:
      return state
  }
}
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import Main from "./main";
import Store from "./redux/store"

ReactDOM.render(
    <Provider store={Store}>
        <BrowserRouter>
            <Main/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)
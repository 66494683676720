import {
  AUTH_ERROR,
  AuthActionType,
  AuthState,
  GET_AREA_LIST,
  LOGIN_AREA_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  SWITCH_BRANCHES,
  WHOAMI,
} from "./types";
import { menuList } from "../../utils/useNavigation";

const initialState: AuthState = {
  area_list: [],
  token_pusat: localStorage.getItem('token_pusat'),
  isAuthPusat: !!localStorage.getItem('token_pusat'),
  isAuthArea: !!localStorage.getItem('token_area'),
  current_area: JSON.parse(localStorage.getItem('area') || '{}'),
  register_status: false,
  user: null
}

export default function (state = initialState, action: AuthActionType): AuthState {
  switch (action.type) {
    case WHOAMI:
      localStorage.setItem('user', JSON.stringify(action.payload))
      action.payload.privilege = action.payload.privilege.map(i => {
        let result = i
        result.permission = menuList.filter(j => i.permission.indexOf(j) >= 0)
        return result
      })
      return {
        ...state,
        user: action.payload
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        register_status: action.payload
      }
    case GET_AREA_LIST:
      return {
        ...state,
        area_list: action.payload
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('token_pusat', action.payload)
      return {
        ...state,
        isAuthPusat: true,
        token_pusat: action.payload
      }
    case LOGIN_AREA_SUCCESS:
      localStorage.setItem('token_area', action.payload.token)
      localStorage.setItem('area', JSON.stringify(action.payload))
      return {
        ...state,
        isAuthArea: true,
        current_area: action.payload
      }
    case SWITCH_BRANCHES:
      localStorage.removeItem('token_area')
      localStorage.removeItem('area')
      return {
        ...state,
        isAuthArea: false,
        current_area: null
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.clear()
      return {
        ...state,
        token_pusat: null,
        user: null,
        current_area: null,
        isAuthPusat: false,
        isAuthArea: false,
        register_status: false
      }
    default:
      return state
  }
}
import {
  ACTIVATE_BUNDLE,
  ALLOW_OTHER_BUNDLE,
  DEACTIVATE_BUNDLE,
  FORBID_OTHER_BUNDLE,
  GET_BUNDLE_LIST,
  BundlingActionType,
  BundlingState
} from "./types";

const initialState: BundlingState = {
  bundle_list: {
    docs: [],
    totalDocs: 0,
    offset: 0,
    limit: 0,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  }
}

export default function (state= initialState, action: BundlingActionType): BundlingState {
  switch (action.type) {
    case GET_BUNDLE_LIST:
      return {
        ...state,
        bundle_list: action.payload
      }
    case ACTIVATE_BUNDLE:
    case DEACTIVATE_BUNDLE:
    case ALLOW_OTHER_BUNDLE:
    case FORBID_OTHER_BUNDLE:
      return {
        ...state
      }
    default:
      return state
  }
}
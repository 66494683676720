import React from "react";
import CIcon from '@coreui/icons-react'
import {
    cilTags,
    cilApple,
    cilBeachAccess,
    cilFile,
    cilGift,
    cilHome,
    cilInput,
    cilLibrary,
    cilCart,
    cilChatBubble,
    cilQrCode,
    cilClock,
    cilAlarm,
    cilStorage,
    cilBasket
} from "@coreui/icons"
import { UserLoggedIn } from "../redux/auth/types";
import { useTypedSelector } from "../redux/rootReducer";

export interface NavMenuType {
    _tag: string
    name: string
    to: string
    icon: JSX.Element
}

export const menuList = [
    'Product Category',
    'Product',
    'Stock',
    'Kategori Blog',
    'Blog',
    'Payment',
    'Voucher',
    'Bundling',
    'Greeting',
    'Order',
    'Order Configuration',
    'Outlet',
    'Delivery',
    'Holiday',
    // 'Order Limiter',
    'Home Slider',
    'Report',
    'Whatsapp',
]

const setMenu = (name: string) => {
    switch (name) {
        default: break
    }
}

const useNavigation = (user: UserLoggedIn | null) => {
    
    const currentArea = useTypedSelector(state => state.auth.current_area)
    
    let initialNav: NavMenuType | any = [
        {
            _tag: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: <CIcon content={cilHome} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavDivider'
        },
        {
            _tag: 'CSidebarNavTitle',
            _children: ['Manajemen Konten']
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Product Category',
            to: '/product-category',
            icon: <CIcon content={cilLibrary} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Product',
            to: '/product',
            icon: <CIcon content={cilApple} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Stock',
            to: '/stock',
            icon: <CIcon content={cilStorage} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Home Slider',
            to: '/home-slider',
            icon: <CIcon content={cilInput} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Voucher',
            to: '/voucher',
            icon: <CIcon content={cilTags} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Bundling',
            to: '/bundling',
            icon: <CIcon content={cilGift} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Greeting',
            to: '/greeting',
            icon: <CIcon content={cilChatBubble} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Order',
            to: '/order',
            icon: <CIcon content={cilCart} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Outlet',
            to: '/outlet',
            icon: <CIcon content={cilBasket} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Delivery',
            to: '/delivery',
            icon: <CIcon content={cilClock} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Holiday',
            to: '/holiday',
            icon: <CIcon content={cilBeachAccess} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Order Configuration',
            to: '/order-configuration',
            icon: <CIcon content={cilAlarm} customClasses="c-sidebar-nav-icon" />
        },
        /*{
            _tag: 'CSidebarNavItem',
            name: 'Order Limiter',
            to: '/order-limiter',
            icon: <CIcon content={cilHttps} customClasses="c-sidebar-nav-icon" />
        },*/
        {
            _tag: 'CSidebarNavItem',
            name: 'Whatsapp QR',
            to: '/whatsapp-qr',
            icon: <CIcon content={cilQrCode} customClasses="c-sidebar-nav-icon" />
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Report',
            to: '/report',
            icon: <CIcon content={cilFile} customClasses="c-sidebar-nav-icon" />
        },
    ]
    
    if (user) {
        let navTmp = initialNav
        
        user.privilege.forEach(item => {
            const { permission } = item
            if ( currentArea !== null && item.area === currentArea.nama) {
                permission.forEach(menu => {
                    if (!navTmp.some((nav: any) => nav.name === menu)) {
                        const newMenu = setMenu(menu);
                        if (Array.isArray(newMenu)) {
                            navTmp = [...navTmp, ...newMenu].filter((item: any) => item !== undefined);
                        } else {
                            navTmp = [...navTmp, newMenu].filter((item: any) => item !== undefined);
                        }
                    }
                });
            }
        })
        
        initialNav = navTmp.filter((item: any) => item !== undefined)
    }

    return [initialNav]
}

export default useNavigation
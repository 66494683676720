export const GET_BUNDLE_LIST = 'GET_BUNDLE_LIST'
export const ACTIVATE_BUNDLE = 'ACTIVATE_BUNDLE'
export const DEACTIVATE_BUNDLE = 'DEACTIVATE_BUNDLE'
export const ALLOW_OTHER_BUNDLE = 'ALLOW_OTHER_BUNDLE'
export const FORBID_OTHER_BUNDLE = 'FORBID_OTHER_BUNDLE'

export interface Configuration {
  module: string;
  target: string;
  quota: number;
  fixed: number;
  percentage: number;
  minimumNominal: number;
  maximumDiscount: number;
  popup_type: string;
  popup_title: string;
  popup_body: string;
  notification_title: string;
  notification_body: string;
}

export interface ExternalVoucher {
  configuration: Configuration;
  _id: string;
  name: string;
  voucher: string;
  qty: number;
}

export interface Doc {
  allowed_other_bundling: boolean;
  isActive: boolean;
  _id: string;
  name: string;
  module: string;
  server: string;
  nominal_threshold: number;
  repetitive: boolean;
  lifetime: number;
  external_vouchers: ExternalVoucher[];
  required_product: any[];
  items: any[];
  vouchers: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface BundlingType {
  docs: Doc[];
  totalDocs: number;
  offset: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage?: any;
  nextPage?: any;
}

export interface GetListType {
  type: typeof GET_BUNDLE_LIST
  payload: BundlingType
}

export interface ActivateBundleType {
  type: typeof ACTIVATE_BUNDLE
  payload: Doc
}

export interface DeactivateBundleType {
  type: typeof DEACTIVATE_BUNDLE
  payload: Doc
}

export interface AllowOtherBundleType {
  type: typeof ALLOW_OTHER_BUNDLE
  payload: Doc
}

export interface ForbidOtherBundleType {
  type: typeof FORBID_OTHER_BUNDLE
  payload: Doc
}

export interface BundlingState {
  bundle_list: BundlingType
}

export type BundlingActionType =
  | GetListType
  | ActivateBundleType
  | DeactivateBundleType
  | AllowOtherBundleType
  | ForbidOtherBundleType
import { combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";

import auth from './auth/reducers'
import bundling from './backOffice/bundling/reducers'
import config from './backOffice/orderConfig/reducers'
import delivery from './backOffice/delivery/reducers'
import greeting from './backOffice/greeting/reducers'
import holiday from './backOffice/holiday/reducers'
import homeSlider from './backOffice/homeSlider/reducers'
import order from './backOffice/order/reducers'
import orderLimiter from './backOffice/orderLimiter/reducers'
import outlet from './backOffice/outlet/reducers'
import payment from './backOffice/payment/reducers'
import product from './backOffice/product/reducers'
import productCategory from './backOffice/productCategory/reducers'
import report from './backOffice/report/reducers'
import stock from './backOffice/stock/reducers'
import support from './support/reducers'
import voucher from './backOffice/voucher/reducers'
import whatsapp from './googleFirebase/reducers'

import { AuthState } from "./auth/types";
import { BundlingState } from "./backOffice/bundling/types";
import { ConfigState } from "./backOffice/orderConfig/types";
import { DeliveryState } from "./backOffice/delivery/types";
import { GreetingState } from "./backOffice/greeting/types";
import { HolidayState } from "./backOffice/holiday/types";
import { HomeSliderState } from "./backOffice/homeSlider/types";
import { OrderState } from "./backOffice/order/types";
import { OrderLimiterState } from "./backOffice/orderLimiter/types";
import { OutletState } from "./backOffice/outlet/types";
import { PaymentState } from "./backOffice/payment/types";
import { ProductState } from "./backOffice/product/types";
import { ProductCategoryState } from "./backOffice/productCategory/types";
import { ReportState } from "./backOffice/report/types";
import { StockState } from "./backOffice/stock/types";
import { SupportState } from "./support/types";
import { VoucherState } from "./backOffice/voucher/types";
import { WhatsappAPIState } from "./googleFirebase/types";

interface RootState {
  auth: AuthState;
  bundling: BundlingState;
  config: ConfigState;
  delivery: DeliveryState;
  greeting: GreetingState;
  holiday: HolidayState;
  homeSlider: HomeSliderState;
  order: OrderState;
  orderLimiter: OrderLimiterState;
  outlet: OutletState;
  payment: PaymentState;
  product: ProductState;
  productCategory: ProductCategoryState;
  report: ReportState;
  stock: StockState
  support: SupportState;
  voucher: VoucherState;
  whatsapp: WhatsappAPIState;
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default combineReducers({
  auth,
  bundling,
  config,
  delivery,
  greeting,
  holiday,
  homeSlider,
  order,
  orderLimiter,
  outlet,
  payment,
  product,
  productCategory,
  report,
  stock,
  support,
  voucher,
  whatsapp
});
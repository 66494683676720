import { GET_STOCK_LIST, StockActionType, StockState } from "./types";

const intitalState: StockState = {
  stock_list: []
}

export default function (state =  intitalState, action: StockActionType): StockState {
  switch (action.type) {
    case GET_STOCK_LIST:
      return {
        ...state,
        stock_list: action.payload
      }
    default:
      return state
  }
}
import {
  PaymentState,
  GET_ACTIVE_PAYMENT_LIST,
  GET_PAYMENT_LIST,
} from "./types";

const initialState: PaymentState = {
  payment_list: [],
  active_payment_list: []
}

export default function(state = initialState, action: any): PaymentState {
  switch (action.type) {
    case GET_PAYMENT_LIST:
      return {
        ...state,
        payment_list: action.payload
      }
    case GET_ACTIVE_PAYMENT_LIST:
      return {
        ...state,
        active_payment_list: action.payload.filter((i: any) => i.enabled)
      }
    default:
      return state
  }
}
      
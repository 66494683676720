export const GET_GREETING_LIST = 'GET_GREETING_LIST'

export interface GreetingList {
  _id: string
  createdAt: string,
  deleted: boolean,
  key: string,
  updatedAt: string,
  value: string,
  __v: number,
}

interface GetGreetingListType {
  type: typeof GET_GREETING_LIST
  payload: GreetingList[]
}

export interface GreetingState {
  greeting_list: GreetingList[]
}

export type GreetingActionType =
  | GetGreetingListType

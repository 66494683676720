import {
  TOGGLE_SIDEBAR,
  GET_ERRORS,
  SET_ALERT,
  REMOVE_ALERT,
  SupportState,
  SupportActionType,
  SET_CURRENT,
  CLEAR_CURRENT,
  DATA_LOADING,
  DATA_LOADED, SET_CURRENT_2, CLEAR_CURRENT_2
} from "./types";

const initialState: SupportState = {
  sidebarShow: 'responsive',
  msg: null,
  alert: [],
  status: null,
  current: null,
  current2: null,
  isLoading: 'loaded'
}

export default function(state = initialState, action: SupportActionType): SupportState {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        isLoading: 'loading'
      }
    case DATA_LOADED:
      return {
        ...state,
        isLoading: 'loaded'
      }
    case GET_ERRORS:
      return {
        ...state,
        msg: action.payload.msg,
        status: action.payload.status
      }
    case SET_ALERT:
      return { ...state, alert: action.payload }
    case REMOVE_ALERT:
      return {
        ...state,
        msg: null,
        alert: state.alert.filter(al => al.id !== action.payload)
      }
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarShow: action.payload }
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload
      }
    case SET_CURRENT_2:
      return {
        ...state,
        current2: action.payload
      }
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null
      }
    case CLEAR_CURRENT_2:
      return {
        ...state,
        current2: null
      }
    default:
      return state
  }
}

import { areaType, GET_REGIONAL_REPORT, GET_REQUEST_URL, ReportActionType, ReportState } from "./types";

const initialState: ReportState = {
  requestURL: {url: ''},
  regionalReport: {
    total: 0,
    area: null,
    unidentified: []
  }
}

export default function(state = initialState, action: ReportActionType): ReportState {
  switch (action.type) {
    case GET_REQUEST_URL:
      return {
        ...state,
        requestURL: action.payload
      }
    case GET_REGIONAL_REPORT:
      return {
        ...state,
        regionalReport: action.payload
      }
    default:
      return state
  }
}

export const AUTH_ERROR = 'AUTH_ERROR'
export const GET_AREA_LIST = 'GET_AREA_LIST'
export const LOGIN_AREA_SUCCESS = 'LOGIN_AREA_SUCCESS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'
export const SWITCH_BRANCHES = 'SWITCH_BRANCHES'
export const WHOAMI = 'WHOAMI'

interface Privilege {
  area: string
  nama: string
  permission: string[]
}

export interface UserLoggedIn {
  iat: number
  ip: any
  nama: string
  privilege: Privilege[]
  uid: string
}

export interface Location {
  _id: string;
  coordinates: number[];
  type: string;
}

export interface Module {
  customer: string;
  name: string;
  office: string;
  status: boolean;
}

export interface Apps {
  "i-food": Module;
  "i-sayur": Module;
}

export interface AreaType {
  _id: string;
  analytics: string;
  apps: Apps;
  code: number;
  createdAt: string
  isProduction: boolean;
  location: Location;
  nama: string;
  released: boolean;
  server: string;
  timezone: string;
  updatedAt: string
  version: string;
  __v: number;
}

interface LoginAreaType extends AreaType {
  token: string
}

export interface WhoAmIType {
  type: typeof WHOAMI
  payload: UserLoggedIn
}

export interface RegisterSuccessType {
  type: typeof REGISTER_SUCCESS
  payload: boolean
}

export interface LoginSuccessType {
  type: typeof LOGIN_SUCCESS
  payload: string
}

export interface AuthErrorType {
  type: typeof AUTH_ERROR
}

export interface GetAreaType {
  type: typeof GET_AREA_LIST
  payload: AreaType[]
}

export interface AreaLoggedInType {
  type: typeof LOGIN_AREA_SUCCESS
  payload: LoginAreaType
}

export interface LogoutSuccessType {
  type: typeof LOGOUT_SUCCESS
}

export interface SwitchBranchesType {
  type: typeof SWITCH_BRANCHES
}

export interface AuthState {
  token_pusat: null | string
  isAuthPusat: boolean
  isAuthArea: boolean
  register_status: boolean
  user: null | UserLoggedIn
  current_area: null | AreaType
  area_list: AreaType[]
}

export type AuthActionType =
  | AreaLoggedInType
  | AuthErrorType
  | GetAreaType
  | LoginSuccessType
  | LogoutSuccessType
  | RegisterSuccessType
  | SwitchBranchesType
  | WhoAmIType

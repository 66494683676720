export const GET_PRODUCT_CATEGORY_LIST = 'GET_PRODUCT_CATEGORY_LIST'
export const GET_TRASHED_LIST = 'GET_TRASHED_LIST'
export const ADD_PRODUCT_CATEGORY = 'ADD_PRODUCT_CATEGORY'
export const EDIT_PRODUCT_CATEGORY = 'EDIT_PRODUCT_CATEGORY'
export const SOFT_DELETE_PRODUCT_CTG = 'SOFT_DELETE_PRODUCT_CTG'
export const HARD_DELETE_PRODUCT_CTG = 'HARD_DELETE_PRODUCT_CTG'
export const RESTORE_PRODUCT_CTG = 'RESTORE_PRODUCT_CTG'

export interface Icon {
  _id: string;
  name: string;
  path: string;
  resources: string[];
  url: string;
}

export interface Doc {
  isPublished: boolean;
  _id: string;
  name: string;
  urutan: number;
  icon: Icon
}

export interface ProductCategory {
  docs: Doc[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number;
  nextPage: null | number;
}

export interface GetListType {
  type: typeof GET_PRODUCT_CATEGORY_LIST
  payload: ProductCategory
}

export interface GetTrashedListType {
  type: typeof GET_TRASHED_LIST
  payload: Doc[]
}

export interface AddFoodCtgType {
  type: typeof ADD_PRODUCT_CATEGORY
  payload: ProductCategory
}

export interface EditFoodCtgType {
  type: typeof EDIT_PRODUCT_CATEGORY
  payload: ProductCategory
}

export interface ProductCategoryState {
  products_ctg: ProductCategory
  trashed_product_category: Doc[]
}

export type ProductCategoryActionType =
  | GetListType
  | AddFoodCtgType
  | EditFoodCtgType
  | GetTrashedListType
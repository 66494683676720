import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

import { GlobalStyles } from "./styles";
import PrivateRoute from "./components/common/PrivateRoute";
import { Alert } from "./components/layout";

const loading = (
  <div className={"pt-3 text-center"}>
    <div className={"sk-spinner sk-spinner-pulse"}></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

const LoginArea = React.lazy(() => import('./pages/Area/Area'))
const LoginPage = React.lazy(() => import('./pages/Auth/Login'))
const Register = React.lazy(() => import('./pages/Auth/Register'))
const RequestResetPassword = React.lazy(() => import('./pages/Auth/RequestToResetPassword'))
const ResetPassword = React.lazy(() => import('./pages/Auth/ResetPassword'))
const VerifySuccessPage = React.lazy(() => import('./pages/Verify/VerifySuccess'))


const Main: React.FC = () => {
  return (
    <>
      <GlobalStyles/>
      <Router>
        <Alert />
        <Suspense fallback={loading}>
          <Switch>
            <Route exact path={"/login"} component={LoginPage}/>
            <Route exact path={"/area"} component={LoginArea}/>
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset-password" component={RequestResetPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/verify/:token" component={VerifySuccessPage} />
            
            <PrivateRoute path={"/"} name={"Main"} component={DefaultLayout}/>
          </Switch>
        </Suspense>
      </Router>
    </>
  )
}

export default withRouter(Main);
import { GET_ALL_PRODUCT_LIST, GET_PRODUCT_LIST, GET_SEARCH_KEY_PRODUCT_LIST, ProductActiontype, ProductState } from "./types";

const initialState: ProductState = {
  product_list: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  all_product_list: [],
  trashed_product_list: [],
  search_key_product: {
    isSearching: false,
    searchKey: '',
    isPublished: 1,
    isFeatured: 0,
    isSale: 0,
    isFlashSale: 0,
    categories: ''
  },
}

export default function (state= initialState, action: ProductActiontype): ProductState {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return {
        ...state,
        product_list: action.payload
      }
    case GET_ALL_PRODUCT_LIST:
      return {
        ...state,
        all_product_list: action.payload
      }
    case GET_SEARCH_KEY_PRODUCT_LIST:
      return {
        ...state,
        search_key_product: {...state.search_key_product, ...action.payload}
      }
    default:
      return state
  }
}
export const GET_OUTLET_LIST = 'GET_OUTLET_LIST'

export interface Location {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface OutletType {
  active: boolean;
  alamat: string;
  location: Location;
  nama: string;
  range: number;
  __v: number;
  _id: string;
}

interface GetOutletListType {
  type: typeof GET_OUTLET_LIST
  payload: OutletType[]
}

export interface OutletState {
  outlet_list: OutletType[]
}

export type OutletActionType =
  | GetOutletListType
import {
  GET_AVAILABLE_DRIVER_LIST,
  GET_FILTER_STATUS_ORDER_LIST,
  GET_ORDER_LIST,
  GET_SEARCH_KEY_ORDER_LIST,
  OrderActionType,
  OrderState
} from './types'

const initialState: OrderState = {
  order_list: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  search_key_order: {
    isSearching: false,
    searchKey: '',
    searchDate: ''
  },
  filter_status: '',
  driver_list: []
}

export default function (state= initialState, action: OrderActionType): OrderState {
  switch (action.type) {
    case GET_ORDER_LIST:
      return {
        ...state,
        order_list: action.payload
      }
    case GET_SEARCH_KEY_ORDER_LIST:
      return {
        ...state,
        search_key_order: action.payload
      }
    case GET_FILTER_STATUS_ORDER_LIST:
      return {
        ...state,
        filter_status: action.payload
      }
    case GET_AVAILABLE_DRIVER_LIST:
      return {
        ...state,
        driver_list: action.payload
      }
    default:
      return state
  }
}
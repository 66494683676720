import {
  DeliveryActionType,
  DeliveryState,
  GET_DELIVERY,
  GET_DRIVER_LIST,
  GET_SHIPPING_LIST,
  SET_DELIVERY
} from "./types";

const initialState : DeliveryState = {
  delivery_list: {
    shipping_start: "",
    shipping_end: "",
    shipping_interval: "",
    shipping_limit: "",
    shipping_final: [],
    shipping_limit_final: {},
  },
  shipping_list: {},
  driver_list: []
}

export default function (state=initialState, action:DeliveryActionType): DeliveryState {
  switch (action.type){
    case GET_DELIVERY:
    case SET_DELIVERY:
      return {
        ...state,
        delivery_list: action.payload
      }
    case GET_SHIPPING_LIST:
      return {
        ...state,
        shipping_list: action.payload
      }
    case GET_DRIVER_LIST:
      return {
        ...state,
        driver_list: action.payload
      }
    default:
      return state
  }
}
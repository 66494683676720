import { GET_HOLIDAY_LIST, HolidayActionType, HolidayState } from './types'

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 0,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: HolidayState = {
  holiday_list: defaultList
}

export default function(state= initialState, action: HolidayActionType):HolidayState {
  switch (action.type) {
    case GET_HOLIDAY_LIST:
      return {
        ...state,
        holiday_list: action.payload
      }
    default:
      return state
  }
}

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const GET_ERRORS = 'GET_ERRORS'
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const SET_CURRENT = 'SET_CURRENT'
export const SET_CURRENT_2 = 'SET_CURRENT_2'
export const CLEAR_CURRENT = 'CLEAR_CURRENT'
export const CLEAR_CURRENT_2 = 'CLEAR_CURRENT_2'
export const DATA_LOADING = 'DATA_LOADING'
export const DATA_LOADED = 'DATA_LOADED'

type LoadingIndicator = 'loading' | 'loaded'

export interface Error {
  msg: string
  status: number
}

export interface ReturnErrorType {
  type: typeof GET_ERRORS
  payload: Error
}

export type Sidebar = 'responsive' | true | false

export interface ToggleSidebarType {
  type: typeof TOGGLE_SIDEBAR
  payload: Sidebar
}

export interface SetAlertType {
  type: typeof SET_ALERT
  payload: { msg: string; type: string; id: string }[]
}

export interface RemoveAlertType {
  type: typeof REMOVE_ALERT
  payload: string
}

export interface SetCurrentType {
  type: typeof SET_CURRENT
  payload: any
}

export interface SetCurrent2Type {
  type: typeof SET_CURRENT_2
  payload: any
}

export interface ClearCurrentType {
  type: typeof CLEAR_CURRENT
}

export interface ClearCurrent2Type {
  type: typeof CLEAR_CURRENT_2
}

export interface DataLoadingType {
  type: typeof DATA_LOADING
}

export interface DataLoadedType {
  type: typeof DATA_LOADED
}

export interface SupportState {
  sidebarShow: Sidebar
  msg: string | null
  alert: { msg: string; type: string; id: string }[]
  status: number | null
  current: any | null
  current2: any | null
  isLoading: LoadingIndicator
}

export type SupportActionType =
  | DataLoadingType
  | DataLoadedType
  | ReturnErrorType
  | ToggleSidebarType
  | RemoveAlertType
  | SetAlertType
  | SetCurrentType
  | SetCurrent2Type
  | ClearCurrentType
  | ClearCurrent2Type

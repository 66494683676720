export const GET_ORDER_CONFIG = 'GET_ORDER_CONFIG'

export interface Value {
  distance: number;
  nominal: number;
}

export interface ConfigList {
  deleted: boolean;
  _id: string;
  key: string;
  value: Value[] | string | any;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface GetConfigListType {
  type: typeof GET_ORDER_CONFIG
  payload: ConfigList[]
}

export interface ConfigState {
  config_list: ConfigList[]
}

export type ConfigActionType =
  | GetConfigListType
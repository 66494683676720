import {
  GET_OUTLET_LIST,
  OutletActionType,
  OutletState
} from "./types";

const initialState: OutletState = {
  outlet_list: []
}

export default function (state= initialState, action: OutletActionType): OutletState {
  switch (action.type) {
    case GET_OUTLET_LIST:
      return {
        ...state,
        outlet_list: action.payload
      }
      default:
        return state
  }
}
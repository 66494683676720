export const GET_DELIVERY = 'GET_DELIVERY'
export const SET_DELIVERY = 'SET_DELIVERY'
export const GET_DRIVER_LIST = 'GET_DRIVER_LIST'
export const GET_SHIPPING_LIST = 'GET_SHIPPING_LIST'

export interface DeliveryPayload {
  shipping_start: string;
  shipping_end: string;
  shipping_interval: string;
  shipping_limit: string;
  shipping_final: string[];
  shipping_limit_final: { [shipping_time: string]: number };
}

export interface ShippingPayload {
  [shipping_time: string]: {
    limit: string;
    current: string;
  }
}

export interface LastLogin {
  ipAddress: string;
  deviceType: string;
  timestamp: Date;
}

export interface Drivers {
  verified: boolean;
  disabled: boolean;
  deleted: boolean;
  _id: string;
  nama: string;
  hp: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  last_login: LastLogin;
  totalUpah: number;
}

export interface getDeliveryType {
  type: typeof GET_DELIVERY
  payload: DeliveryPayload
}

export interface setDeliveryType {
  type: typeof SET_DELIVERY
  payload: DeliveryPayload
}

export interface getShippingListType {
  type: typeof GET_SHIPPING_LIST
  payload: ShippingPayload
}

export interface getDriverListType {
  type: typeof GET_DRIVER_LIST
  payload: Drivers[]
}

export interface DeliveryState {
  delivery_list: DeliveryPayload
  shipping_list: ShippingPayload
  driver_list: Drivers[]
}

export type DeliveryActionType =
  | getDeliveryType
  | setDeliveryType
  | getShippingListType
  | getDriverListType

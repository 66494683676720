export const GET_ORDER_LIMITER_LIST= 'GET_ORDER_LIMITER_LIST'

export interface OrderLimiterList {
  _id: string
  createdAt: string,
  issuer: string,
  limit: boolean,
  shipping_date: string,
  updatedAt: string,
  __v: number,
}

interface GetOrderLimiterListType {
  type: typeof GET_ORDER_LIMITER_LIST
  payload: OrderLimiterList[]
}

export interface OrderLimiterState {
  limiter_list: OrderLimiterList[]
}

export type OrderLimiterActionType =
  | GetOrderLimiterListType
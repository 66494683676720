import { Doc } from "../product/types";
import { Drivers } from '../delivery/types'
import { OutletType } from "../outlet/types";

export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const GET_SEARCH_KEY_ORDER_LIST = 'GET_SEARCH_KEY_ORDER_LIST'
export const GET_FILTER_STATUS_ORDER_LIST = 'GET_FILTER_STATUS_ORDER_LIST'
export const GET_AVAILABLE_DRIVER_LIST = 'GET_AVAILABLE_DRIVER_LIST'

export interface Device {
  isiPhone: boolean;
  isAndroid: boolean;
  isDesktop: boolean;
  browser: string;
  platform: string;
}

export interface DeliveryAddress {
  nama: string;
  alamat: string;
  email: string;
  hp: string;
}

export interface Item {
  isSale: boolean;
  _id: string;
  product: Doc;
  qty: number;
  price: number;
  sale: number;
  discount: number;
  total: number;
  addOn: any[];
}

export interface DeliveryLocation {
  coordinates: number[];
  _id: string;
  type: string;
}

export interface PaymentMethod {
  enabled: boolean;
  deleted: boolean;
  _id: string;
  code: string;
  description: string;
  name: string;
  order: number;
  minimum: number;
  maximum?: any;
}

export interface ChannelProperties {
  success_redirect_url: string;
}

export interface Actions {
  desktop_web_checkout_url: string;
  mobile_web_checkout_url: string;
  mobile_deeplink_checkout_url?: any;
  qr_checkout_string?: any;
}

export interface Metadata {
  area: string;
  callback: string;
  isProduction: boolean;
  module: string;
}

export interface Payment {
  id: string;
  business_id: string;
  reference_id: string;
  status: string;
  currency: string;
  charge_amount: number;
  capture_amount: number;
  refunded_amount?: any;
  checkout_method: string;
  channel_code: string;
  channel_properties: ChannelProperties;
  actions: Actions;
  is_redirect_required: boolean;
  callback_url: string;
  created: string;
  updated: string;
  void_status?: any;
  voided_at?: any;
  capture_now: boolean;
  customer_id?: any;
  payment_method_id?: any;
  failure_code?: any;
  basket?: any;
  metadata: Metadata;
}

interface Rule {
  food: any[];
  _id: string;
  type: string;
  value: number;
}

export interface Voucher {
  isPercentage: boolean;
  isGlobal: boolean;
  isActive: boolean;
  isException: boolean;
  isPreorder: boolean;
  items: any[];
  withPromo: boolean;
  deleted: boolean;
  _id: string;
  nama: string;
  deskripsi: string;
  value: number;
  maxDiscount: number;
  startAt: Date;
  endAt: Date;
  rules: Rule[];
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface Customer {
  _id: string,
  isNewCustomer: boolean,
  orderSukses: number
}

export interface OrderItem {
  device: Device;
  status: string;
  isReady: boolean;
  isPaid: boolean;
  isDelivered: boolean;
  isCanceled: boolean;
  isProcessed: boolean;
  isOtw: boolean;
  isRefunded: boolean;
  paidAt?: string;
  deliveryAt: string;
  deliveredAt?: string;
  deliveryAddress: DeliveryAddress;
  history: any[];
  distance: number;
  shipping_date: string;
  shipping_time: string;
  cost: number;
  deleted: boolean;
  _id: number;
  totalQty: number;
  total: number;
  totalSale: number;
  totalDiscount: number;
  totalNormalPrice: number;
  customer: Customer;
  items: Item[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  deliveryLocation: DeliveryLocation;
  payment_method: PaymentMethod;
  payment: Payment;
  voucher: Voucher | null;
  outlet: OutletType;
}

export interface Order {
  docs: OrderItem[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number;
  nextPage: null | number;
}

export interface SearchKey {
  isSearching: boolean,
  searchKey: string,
  searchDate: string
}

export interface GetOrderListType {
  type: typeof GET_ORDER_LIST
  payload: Order
}

export interface GetSearchKeyType {
  type: typeof GET_SEARCH_KEY_ORDER_LIST
  payload: SearchKey
}

export interface GetFilterStatusType {
  type: typeof GET_FILTER_STATUS_ORDER_LIST
  payload: string
}

export interface GetAvailableDriverListType {
  type: typeof GET_AVAILABLE_DRIVER_LIST
  payload: Drivers[]
}

export interface OrderState {
  order_list: Order
  search_key_order: SearchKey
  filter_status: string
  driver_list: Drivers[]
}

export type OrderActionType =
  | GetOrderListType
  | GetSearchKeyType
  | GetFilterStatusType
  | GetAvailableDriverListType
import React from 'react'
import styled from 'styled-components'
import { CContainer, CAlert, CRow, CCol } from '@coreui/react'

import { useTypedSelector } from '../../redux/rootReducer'

const Alert: React.FC = () => {
  const alerts = useTypedSelector(state => state.support.alert)

  return (
    <>
      {alerts.map(alert => (
        <AlertArea key={alert.id}>
          <CContainer className="pt-5">
            <CRow alignHorizontal="center">
              <CCol md={4}>
                <CAlert color={alert.type} className="text-center">
                  {/*<i className="fas fa-info-circle mr-2" />*/}
                  {alert.msg}
                </CAlert>
              </CCol>
            </CRow>
          </CContainer>
        </AlertArea>
      ))}
    </>
  )
}

const AlertArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
`

export default Alert

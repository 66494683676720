export const GET_ACTIVE_PAYMENT_LIST = 'GET_ACTIVE_PAYMENT_LIST'
export const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST'

export interface Detail {
  name: string,
  stakeholder: string,
  number: string,
  icon: string
}

export interface Payment {
  enabled: boolean,
  deleted: boolean,
  _id: string,
  code: string,
  description: string,
  name: string,
  order: number,
  detail?: Detail[]
  __v: number
}

export interface GetPaymentListType {
  type: typeof GET_PAYMENT_LIST
  payload: Payment[]
}

export interface GetActivePaymentListType {
  type: typeof GET_ACTIVE_PAYMENT_LIST
  payload: Payment[]
}

export interface PaymentState {
  payment_list: Payment[]
  active_payment_list: Payment[]
}

export type PaymentActionType =
  | GetPaymentListType
  | GetActivePaymentListType
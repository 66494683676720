export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const GET_ALL_PRODUCT_LIST = 'GET_ALL_PRODUCT_LIST'
export const GET_TRASHED_PRODUCT_LIST = 'GET_TRASHED_PRODUCT_LIST'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const SOFT_DELETE_PRODUCT = 'SOFT_DELETE_PRODUCT'
export const PRODUCT_UPLOAD_PHOTO = 'PRODUCT_UPLOAD_PHOTO'
export const PRODUCT_REMOVE_PHOTO = 'PRODUCT_REMOVE_PHOTO'
export const GET_SEARCH_KEY_PRODUCT_LIST = 'GET_SEARCH_KEY_PRODUCT_LIST'

export interface Category {
  _id: string;
  name: string;
  slug: string;
}

export interface Image {
  _id: string;
  name: string;
  path: string;
  resources: string[];
  url: string;
}

export interface Unit {
  _id: string;
  name: string;
  value: number;
}

export interface Weight {
  actual: number
  name: string
  maxValue: number
  minValue: number
  value: number
}

export interface Doc {
  _id: string;
  addon: any[];
  categories: Category[];
  cost: number;
  createdAt: string;
  deleted: boolean;
  description: string;
  extra: any[];
  images: Image[];
  isFeatured: boolean;
  isFlashSale: boolean;
  flashSaleEnd: number;
  flashSaleMax: number | any;
  flashSalePrice: number;
  flashSaleStart: number;
  isPublished: boolean;
  isSale: boolean;
  metadata: any[];
  name: string;
  price: number;
  saleEnd: string;
  salePrice: number;
  saleStart: string;
  sku: string;
  slug: string;
  shortDescription: string;
  tags: any[];
  type: string;
  unit: Unit;
  updatedAt: string;
  weight: Weight
  __v: number;
}

export interface Product {
  docs: Doc[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number;
  nextPage: null | number;
}

export interface AllProduct {
  _id: string;
  name: string;
  plain_description: string;
  id: string;
}

export interface SearchKey {
  isSearching: boolean,
  searchKey: string,
  isPublished: number,
  isFeatured: number,
  isSale: number,
  isFlashSale: number,
  categories: string
}

export interface GetProductListType {
  type: typeof GET_PRODUCT_LIST
  payload: Product
}

export interface GetAllProdcutListType {
  type: typeof GET_ALL_PRODUCT_LIST
  payload: AllProduct[]
}

export interface GetTrashedProductListType {
  type: typeof GET_TRASHED_PRODUCT_LIST
  payload: Doc[]
}

export interface AddProductType {
  type: typeof ADD_PRODUCT
  payload: Product
}

export interface EditProductType {
  type: typeof EDIT_PRODUCT
  payload: Product
}

export interface SoftDeleteProductType {
  type: typeof SOFT_DELETE_PRODUCT
  payload: Product
}

export interface UploadProductPhotoType {
  type: typeof PRODUCT_UPLOAD_PHOTO
  payload: Product
}

export interface RemoveProductPhotoType {
  type: typeof PRODUCT_REMOVE_PHOTO
  payload: Product
}

export interface GetSearchKeyType {
  type: typeof GET_SEARCH_KEY_PRODUCT_LIST
  payload: SearchKey
}

export interface ProductState {
  product_list: Product
  all_product_list: AllProduct[]
  trashed_product_list: Doc[]
  search_key_product: SearchKey
}

export type ProductActiontype =
  | GetProductListType
  | GetAllProdcutListType
  | GetTrashedProductListType
  | AddProductType
  | EditProductType
  | SoftDeleteProductType
  | UploadProductPhotoType
  | RemoveProductPhotoType
  | GetSearchKeyType
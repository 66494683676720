export const GET_STOCK_LIST = 'GET_STOCK_LIST'

export interface stockType {
  id: string
  sku: string
  name: string
  price: number
  buyPrice: number
  margin: number
  saleMargin: number
  selisih: number
  categories: string[]
}

export interface GetStockList {
  type: typeof GET_STOCK_LIST
  payload: stockType[]
}

export interface StockState {
  stock_list: stockType[]
}

export type StockActionType =
  | GetStockList
export const GET_VOUCHER_LIST = 'GET_VOUCHER_LIST'
export const GET_VOUCHER_TRASHED_LIST = 'GET_VOUCHER_TRASHED_LIST'
export const GET_VOUCHER = 'GET_VOUCHER'
export const ADD_VOUCHER = 'ADD_VOUCHER'
export const EDIT_VOUCHER = 'EDIT_VOUCHER'
export const DELETE_VOUCHER = 'DELETE_VOUCHER'
export const RESTORE_VOUCHER = 'RESTORE_VOUCHER'
export const ACTIVATED_VOUCHER = 'ACTIVATED_VOUCHER'
export const DEACTIVATED_VOUCHER = 'DEACTIVATED_VOUCHER'
export const GET_VOUCHER_RULE = 'GET_VOUCHER_RULE'
export const RESET_VOUCHER_RULE = 'RESET_VOUCHER_RULE'
export const ADD_VOUCHER_RULE = 'ADD_VOUCHER_RULE'
export const EDIT_VOUCHER_RULE = 'EDIT_VOUCHER_RULE'
export const DELETE_VOUCHER_RULE = 'DELETE_VOUCHER_RULE'
export const ADD_VOUCHER_RULE_FOOD = 'ADD_VOUCHER_RULE_FOOD'
export const DELETE_VOUCHER_RULE_FOOD = 'DELETE_VOUCHER_RULE_FOOD'

export type RuleOptionType = 'minimum_nominal' | 'minimum_qty' | 'maximum_qty' | 'required_items' | 'usage'

export interface RuleType {
  product: string[]
  _id: string
  type: RuleOptionType
  value: number
}

export interface VoucherType {
  isPercentage: boolean
  isGlobal: boolean
  isActive: boolean
  isException: boolean
  isPreorder: boolean
  withPromo: boolean
  forPlatform?: string | any
  deleted: boolean
  _id: string
  nama: string
  deskripsi: string
  value: number
  limitPerUser: number
  limitUsage: number
  maxDiscount: number
  startAt: string
  endAt: string
  visibility: string
  createdAt?: string
  updatedAt?: string
  __v?: number
  rules: RuleType[]
  items: string[]
}

export interface GetListType {
  type: typeof GET_VOUCHER_LIST
  payload: VoucherType[]
}

export interface AddVoucherType {
  type: typeof ADD_VOUCHER
  payload: VoucherType
}

export interface EditVoucherType {
  type: typeof EDIT_VOUCHER
  payload: VoucherType
}

export interface DeleteVoucherType {
  type: typeof DELETE_VOUCHER
  payload: VoucherType
}

export interface RestoreVoucherType {
  type: typeof RESTORE_VOUCHER
  payload: VoucherType
}

export interface GetDetailType {
  type: typeof GET_VOUCHER
  payload: VoucherType
}

export interface GetTrashedListType {
  type: typeof GET_VOUCHER_TRASHED_LIST
  payload: VoucherType[]
}

export interface ActivatedVoucherType {
  type: typeof ACTIVATED_VOUCHER
  payload: VoucherType
}

export interface DeactivatedVoucherType {
  type: typeof DEACTIVATED_VOUCHER
  payload: VoucherType
}

export interface GetVoucherRuleType {
  type: typeof GET_VOUCHER_RULE
  payload: RuleType[]
}

export interface AddVoucherRuleType {
  type: typeof ADD_VOUCHER_RULE
  payload: RuleType
}

export interface EditVoucherRuleType {
  type: typeof EDIT_VOUCHER_RULE
  payload: RuleType
}
export interface DeleteVoucherRuleType {
  type: typeof DELETE_VOUCHER_RULE
  payload: RuleType
}

export interface ResetVoucherRuleType {
  type: typeof RESET_VOUCHER_RULE
  payload: VoucherType
}

export interface AddVoucherRuleFoodType {
  type: typeof ADD_VOUCHER_RULE_FOOD
  payload: VoucherType
}

export interface RemoveVoucherRuleFoodType {
  type: typeof DELETE_VOUCHER_RULE_FOOD
  payload: VoucherType
}

export interface VoucherState {
  voucher: null | VoucherType
  voucher_list: VoucherType[]
  voucher_trashed: VoucherType[]
  voucher_rule_list: RuleType[]
}

export type VoucherActionType =
  | GetDetailType
  | GetTrashedListType
  | GetListType
  | AddVoucherType
  | EditVoucherType
  | DeleteVoucherType
  | RestoreVoucherType
  | ActivatedVoucherType
  | DeactivatedVoucherType
  | AddVoucherRuleType
  | GetVoucherRuleType
  | EditVoucherRuleType
  | DeleteVoucherRuleType
  | ResetVoucherRuleType
  | AddVoucherRuleFoodType
  | RemoveVoucherRuleFoodType
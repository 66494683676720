export const GET_REQUEST_URL = 'GET_REQUEST_URL'
export const GET_REGIONAL_REPORT= 'GET_REGIONAL_REPORT'

export interface RequestURLType {
  url: string
}

export interface areaType {
  [region: string]: {
    total: number
    area: {
      [district: string]: {
        total: number
        area: {
          [subDistrict: string]: number
        }
      }
    }
  }
}

export interface RegionalReportType {
  total: number
  area: areaType | null
  unidentified: any[]
}

export interface GetRequestURLType {
  type: typeof GET_REQUEST_URL
  payload: RequestURLType
}

export interface GetRegionalReportType {
  type: typeof GET_REGIONAL_REPORT
  payload: RegionalReportType
}

export interface ReportState {
  requestURL: RequestURLType
  regionalReport: RegionalReportType
}

export type ReportActionType =
  | GetRequestURLType
  | GetRegionalReportType

export const GET_SELECTED_DEVICE = 'GET_SELECTED_DEVICE'
export const GET_NEW_WHATSAPP_STATUS = 'GET_NEW_WHATSAPP_STATUS'

interface getSelectedDevice {
  type: typeof GET_SELECTED_DEVICE
  payload: any
}

interface getNewDeviceStatus {
  type: typeof GET_NEW_WHATSAPP_STATUS
  payload: any
}

export interface WhatsappAPIState {
  selected_device: any
  whatsapp_status: any
}

export type WhatsappAPIActionType =
  | getSelectedDevice
  | getNewDeviceStatus

import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useTypedSelector } from '../../redux/rootReducer'

interface PrivateRouteProps extends RouteProps {
  name?: string
  component: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = props => {
  const {component: Component, ...rest} = props
  const isAuthPusat = useTypedSelector(state => state.auth.isAuthPusat)
  const isAuthArea = useTypedSelector(state => state.auth.isAuthArea)
  
  return (
    <Route
      {...rest}
      render={props =>
        isAuthArea && isAuthPusat ? <Component {...props} /> : !isAuthArea ? <Redirect to="/area"/> :
          <Redirect to="/login"/>
      }
    />
  )
}

export default PrivateRoute

import {
  GET_NEW_WHATSAPP_STATUS,
  GET_SELECTED_DEVICE,
  WhatsappAPIActionType,
  WhatsappAPIState
} from "./types";

const initialize: WhatsappAPIState = {
  selected_device: '',
  whatsapp_status: {}
}

export default function (state = initialize, action: WhatsappAPIActionType): WhatsappAPIState {
  switch (action.type) {
    case GET_SELECTED_DEVICE:
      return {
        ...state,
        selected_device: action.payload
      };
    case GET_NEW_WHATSAPP_STATUS:
      return {
        ...state,
        whatsapp_status: action.payload
      };
    default:
      return state;
  }
}
import {
  GET_PRODUCT_CATEGORY_LIST,
  ProductCategoryState,
  ProductCategoryActionType
} from "./types";

const initialState: ProductCategoryState = {
  products_ctg: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    page: 1,
    totalPages: 1,
    pagingCounter: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  },
  trashed_product_category: []
}

export default function (state = initialState, action: ProductCategoryActionType): ProductCategoryState {
  switch (action.type) {
    case GET_PRODUCT_CATEGORY_LIST:
      return {
        ...state,
        products_ctg: action.payload
      }
    default:
      return state
  }
}